import React, { useContext } from 'react'
import { WidgetContext } from '@/contexts/Widget'
import { Container } from './Grid.styles'
import Widget from './Widget/Widget'
import useDrag from '@/hooks/useDrag'
import useResize from '@/hooks/useResize'
import useDimension from '@/hooks/useDimension'

const Grid: React.FC = () => {
  const { dimension, widgets, grabbing, gridRef, overlayRef } = useContext(WidgetContext)

  useDrag()
  useResize()

  const { gridTemplateColumns } = useDimension()

  return (
    <Container className="container">
      <div ref={gridRef} className="grid">
        <div style={{ gridTemplateColumns }} className="widgets">
          {dimension && (
            <>
              {widgets.filter(({ id, isActive }) => {
                return id === grabbing?.widget.id || isActive
              }).map(widget => (
                <Widget widget={widget} key={widget.id} />
              ))}

              <div ref={overlayRef} className="overlay">
                <div />
              </div>
            </>
          )}
        </div>
      </div>
    </Container>
  )
}

export default Grid
