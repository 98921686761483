import React, { useContext } from 'react'
import { Accordion } from 'react-bootstrap'
import { IoIosOptions } from 'react-icons/io'
import { WidgetContext } from '@/contexts/Widget'
import { AppContext } from '@/contexts/App'
import { Aside } from './Sidebar.styles'
import useGrab from '@/hooks/useGrab'

const Sidebar: React.FC = () => {
  const { isCollapsed, setIsCollapsed } = useContext(AppContext)
  const { widgets, grabbing } = useContext(WidgetContext)

  const { onMouseDown, onMouseMove, onMouseUp } = useGrab()

  return (
    <Aside className="sidebar">
      <button type="button" className="btn btn-primary" onClick={() => setIsCollapsed(!isCollapsed)}>
        <IoIosOptions size={32} />
      </button>

      <div className="widgets">
        <Accordion defaultActiveKey="0">
          {widgets.filter(({ isActive }) => !isActive).map((widget, index) => (
            <Accordion.Item eventKey={index.toString()} key={widget.id}>
              <Accordion.Header>
                {widget.name}
              </Accordion.Header>

              <Accordion.Body>
                <div className={`widget ${grabbing?.widget.id === widget.id ? 'is-grabbing' : ''}`}>
                  <figure className="mb-0">
                    <img src={widget.resource.url} className="img-fluid" alt={widget.name} />
                  </figure>

                  <div
                    className="grabbable"
                    onMouseDown={e => onMouseDown(e, widget)}
                    onMouseMove={e => onMouseMove(e, widget)}
                    onMouseUp={e => onMouseUp(e)}
                  />
                </div>
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </div>
    </Aside>
  )
}

export default Sidebar
