import React, { createContext, useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import useStickyState from '@/hooks/useStickyState'
import axios from 'axios'

type AuthContextProps = {
  token: string | null
  setToken: React.Dispatch<React.SetStateAction<string | null>>
}

export const AuthContext = createContext<AuthContextProps>({ } as AuthContextProps)

const AuthProvider: React.FC = () => {
  const [ token, setToken ] = useStickyState<string | null>('token', null)

  useEffect(() => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
  }, [token])

  return (
    <AuthContext.Provider
      value={{
        token,
        setToken,
      }}
    >
      <Outlet />
    </AuthContext.Provider>
  )
}

export default AuthProvider
