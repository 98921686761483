import { useCallback, useContext } from 'react'
import { WidgetContext } from '@/contexts/Widget'
import { clamp } from 'lodash'
import IWidget from '@/interfaces/IWidget'
import Vector2 from '@/types/Vector2'
import Size from '@/types/Size'
import useOverlay from './useOverlay'

const useGrab = () => {
  const { setWidgets, grabbing, setGrabbing, dimension, staticMap, refs, gridRef, $update } = useContext(WidgetContext)

  const overlay = useOverlay()

  /**
   * Get the position of the widget based on the mouse position
   *
   * @param {IWidget} widget
   * @param {Vector2} mouse
   *
   * @returns {Object | null} The position of the widget
   */
  const getDeltaPosition = useCallback((widget: IWidget, mouse: Vector2) => {
    const rect = (gridRef.current as HTMLDivElement).getBoundingClientRect()

    if (!dimension)
      return null

    const { column, row } = dimension

    const { colSpan, rowSpan } = widget.layouts[dimension?.breakpoint ?? 'lg']

    const size = {
      width: colSpan * column,
      height: rowSpan * row,
    }

    const delta = {
      x: clamp(mouse.x - (rect.left + (size.width / 2)), 0, rect.width - size.width),
      y: Math.max(0, mouse.y - (rect.top + (size.height / 2))),
    }

    const grid = {
      x: Math.max(1, Math.round(delta.x / column) + 1),
      y: Math.max(1, Math.round(delta.y / row) + 1),
    }

    const position = {
      x: mouse.x - (colSpan * column) / 2,
      y: mouse.y - (rowSpan * row) / 2,
    }

    return {
      size,
      position,
      grid,
    }
  }, [dimension, gridRef])

  /**
   * Handle the widget move
   *
   * @param {IWidget} widget
   * @param {Size} size
   * @param {Vector2} position
   * @param {Vector2} grid
   *
   * @returns {void}
   */
  const onWidgetMove = useCallback((widget: IWidget, size: Size, position: Vector2, grid: Vector2) => {
    const element = refs[widget.id]?.current

    if (!element)
      return

    element.style.width = `${size.width}px`
    element.style.height = `${size.height}px`
    element.style.left = `${position.x}px`
    element.style.top = `${position.y}px`
    element.style.opacity = '0.7'

    element.dataset.x = String(grid.x)
    element.dataset.y = String(grid.y)
  }, [refs])

  /**
   * Handle the widget drop
   *
   * @param {IWidget} widget
   *
   * @returns {void}
   */
  const onWidgetDrop = useCallback((widget: IWidget) => {
    const element = refs[widget.id]?.current

    if (!element || !grabbing)
      return

    const { id } = grabbing.widget

    element.style.width = 'auto'
    element.style.height = 'auto'
    element.style.left = 'initial'
    element.style.top = 'initial'
    element.style.opacity = '1'

    setWidgets(widgets => {
      return [
        ...widgets.map(widget => {
          const element = refs[widget.id]?.current

          if (!element)
            return widget

          const { x, y, colSpan, rowSpan } = element.dataset

          widget.layouts[dimension?.breakpoint ?? 'lg'] = {
            x: Number(x),
            y: Number(y),
            colSpan: Number(colSpan),
            rowSpan: Number(rowSpan),
          }

          if (widget.id === id) {
            widget.isActive = true
            $update.mutate(widget)
          }

          return widget
        }),
      ]
    })
  }, [$update, dimension?.breakpoint, grabbing, refs, setWidgets])

  /**
   * Handle the mouse down event
   *
   * @param {React.MouseEvent<HTMLDivElement>} event
   * @param {IWidget} widget
   *
   * @returns {void}
   */
  const onMouseDown = useCallback(({ target, clientX, clientY }: React.MouseEvent<HTMLDivElement>, widget: IWidget) => {
    const element = target as HTMLDivElement

    const rect = element.getBoundingClientRect()

    const deltaPosition = getDeltaPosition(widget, {
      x: clientX,
      y: clientY,
    })

    if (!deltaPosition)
      return

    const { grid } = deltaPosition

    const y = grid.y
    const x = grid.x

    element.style.width = `${rect.width}px`
    element.style.height = `${rect.height}px`
    element.style.left = `${rect.left}px`
    element.style.top = `${rect.top}px`

    setGrabbing({
      widget,
    })

    if (staticMap[y - 1] && staticMap[y - 1][x - 1] && staticMap[y - 1][x - 1] !== true)
      return

    const { colSpan, rowSpan } = widget.layouts[dimension?.breakpoint ?? 'lg']

    overlay.move({
      x,
      y,
      colSpan,
      rowSpan,
    })
  }, [getDeltaPosition, setGrabbing, staticMap, dimension?.breakpoint, overlay])

  /**
   * Handle the mouse move event
   *
   * @param {React.MouseEvent<HTMLDivElement>} event
   * @param {IWidget} widget
   *
   * @returns {void}
   */
  const onMouseMove = useCallback(({ target, clientX, clientY }: React.MouseEvent<HTMLDivElement>, widget: IWidget) => {
    if (grabbing?.widget.id !== widget.id)
      return

    const element = target as HTMLDivElement

    const rect = element.getBoundingClientRect()

    const deltaPosition = getDeltaPosition(widget, {
      x: clientX,
      y: clientY,
    })

    if (!deltaPosition)
      return

    const { size, position, grid } = deltaPosition

    element.style.left = `${clientX - (rect.width / 2)}px`
    element.style.top = `${clientY - (rect.height / 2)}px`

    onWidgetMove(widget, size, position, grid)

    const y = grid.y
    const x = grid.x

    if (staticMap[y - 1] && staticMap[y - 1][x - 1] && staticMap[y - 1][x - 1] !== true)
      return

    const { colSpan, rowSpan } = widget.layouts[dimension?.breakpoint ?? 'lg']

    overlay.move({
      x,
      y,
      colSpan,
      rowSpan,
    })
  }, [grabbing?.widget.id, getDeltaPosition, onWidgetMove, staticMap, dimension?.breakpoint, overlay])

  /**
   * Handle the mouse up event
   *
   * @param {React.MouseEvent<HTMLDivElement>} event
   * @param {IWidget} widget
   *
   * @returns {void}
   */
  const onMouseUp = useCallback(({ target }: React.MouseEvent<HTMLDivElement>) => {
    overlay.hide()

    const element = target as HTMLDivElement

    element.style.width = '100%'
    element.style.height = '100%'
    element.style.left = '0px'
    element.style.top = '0px'

    if (!grabbing)
      return

    onWidgetDrop(grabbing.widget)

    setGrabbing(null)
  }, [overlay, grabbing, onWidgetDrop, setGrabbing])

  return {
    onMouseDown,
    onMouseMove,
    onMouseUp,
  }
}

export default useGrab
