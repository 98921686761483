import { useCallback, useContext, useEffect, useMemo } from 'react'
import { columns, WidgetContext } from '@/contexts/Widget'
import { clamp } from 'lodash'
import useOverlay from './useOverlay'

const useResize = () => {
  const { dimension, resizing, refs, gridRef } = useContext(WidgetContext)

  const overlay = useOverlay()

  const element = useMemo(() => {
    return resizing ? refs[resizing.widget.id].current : null
  }, [resizing, refs])

  /**
   * Handle the mouse move event.
   *
   * @param {MouseEvent} event - The mouse event.
   *
   * @returns {void}
   */
  const onMouseMove = useCallback(({ clientX, clientY }: MouseEvent) => {
    const grid = gridRef.current?.getBoundingClientRect()

    if (!resizing || !element || !grid || !dimension)
      return

    const size = columns[dimension.breakpoint]

    const { widget, offset } = resizing
    const { column, row } = dimension

    const rect = element.getBoundingClientRect()

    const delta = {
      x: Math.max((clientX - rect.left) + offset.x, column),
      y: Math.max((clientY - rect.top) + offset.y, row),
    }

    const colSpan = clamp(Math.round(delta.x / column), 1, size)
    const rowSpan = clamp(Math.round(delta.y / row), 1, size)

    element.style.width = `${delta.x}px`
    element.style.height = `${delta.y}px`

    element.dataset.colSpan = String(colSpan)
    element.dataset.rowSpan = String(rowSpan)

    const { x, y } = widget.layouts[dimension?.breakpoint ?? 'lg']

    const position = {
      x,
      y,
      colSpan,
      rowSpan,
    }

    overlay.move(position)
  }, [gridRef, resizing, element, dimension, overlay])

  useEffect(() => {
    document.addEventListener('mousemove', onMouseMove)

    return () => {
      document.removeEventListener('mousemove', onMouseMove)
    }
  }, [onMouseMove])
}

export default useResize
