import React, { createContext, useState, useRef, useEffect, RefObject, useMemo, useCallback } from 'react'
import { useMutation, UseMutationResult, useQuery, UseQueryResult } from '@tanstack/react-query'
import { Outlet, useLocation } from 'react-router-dom'
import { AxiosResponse } from 'axios'
import IWidget from '@/interfaces/IWidget'
import Dragging from '@/types/Dragging'
import Resizing from '@/types/Resizing'
import Grabbing from '@/types/Grabbing'
import $Widget from '@/services/Widget'
import Dimension from '@/types/Dimension'
import Breakpoint from '@/types/Breakpoint'

export const columns = {
  lg: 12,
  md: 9,
  sm: 6,
  xs: 3,
} as Record<Breakpoint, number>

type WidgetContextProps = {
  widgets: IWidget[]
  setWidgets: React.Dispatch<React.SetStateAction<IWidget[]>>
  dragging: Dragging | null
  setDragging: React.Dispatch<React.SetStateAction<Dragging | null>>
  grabbing: Grabbing | null
  setGrabbing: React.Dispatch<React.SetStateAction<Grabbing | null>>
  resizing: Resizing | null
  setResizing: React.Dispatch<React.SetStateAction<Resizing | null>>
  dimension: Dimension | null
  setDimension: React.Dispatch<React.SetStateAction<Dimension | null>>
  refs: Record<number, RefObject<HTMLDivElement>>
  setRefs: React.Dispatch<React.SetStateAction<Record<number, RefObject<HTMLDivElement>>>>
  staticMap: (number | true)[][]
  isAdmin?: boolean
  clientId: number | null
  gridRef: React.RefObject<HTMLDivElement>
  overlayRef: React.RefObject<HTMLDivElement>
  $widgets: UseQueryResult<IWidget[]>
  $update: UseMutationResult<AxiosResponse<void, any>, Error, IWidget, unknown>
  onDisable: (id: number) => void
}

export const WidgetContext = createContext<WidgetContextProps>({} as WidgetContextProps)

type Props = {
  isAdmin?: boolean
}

const WidgetProvider: React.FC<Props> = ({ isAdmin }) => {
  const [ widgets, setWidgets ] = useState<IWidget[]>([])
  const [ dragging, setDragging ] = useState<Dragging | null>(null)
  const [ grabbing, setGrabbing ] = useState<Grabbing | null>(null)
  const [ resizing, setResizing ] = useState<Resizing | null>(null)
  const [ refs, setRefs ] = useState<Record<number, RefObject<HTMLDivElement>>>({})
  const [ dimension, setDimension ] = useState<Dimension | null>(null)

  const { search } = useLocation()

  const gridRef = useRef<HTMLDivElement>(null)

  const overlayRef = useRef<HTMLDivElement>(null)

  const updateRef = useRef({
    controllers: {} as Record<number, AbortController>,
    take: (id: number) => {
      if (updateRef.current.controllers[id])
        updateRef.current.controllers[id].abort()

      return updateRef.current.controllers[id] = new AbortController()
    },
  })

  const clientId = useMemo(() => {
    const params = new URLSearchParams(search)

    if (!params.has('clientId'))
      return null

    return Number(params.get('clientId'))
  }, [search])

  const $widgets = useQuery({
    queryFn: $Widget.all,
    queryKey: [
      'widgets', clientId, isAdmin,
    ],
  })

  const $update = useMutation({
    mutationFn: (widget: IWidget) => {
      const { signal } = updateRef.current.take(widget.id)

      return $Widget.update({
        widget,
        clientId,
        isAdmin,
        signal,
      })
    },
    mutationKey: [
      'update',
    ],
  })

  const $disable = useMutation({
    mutationFn: $Widget.disable,
    mutationKey: [
      'disable',
    ]
  })

  useEffect(() => {
    if ($widgets.data) {
      setWidgets($widgets.data)
    }
  }, [$widgets.data])

  /**
   * Create a map of the static widgets.
   *
   * @returns The static map.
   */
  const staticMap = useMemo(() => {
    if (!widgets.length || !dimension)
      return []

    const length = widgets.reduce((acc, { layouts }) => {
      const { y, rowSpan } = layouts[dimension?.breakpoint ?? 'lg']
      return Math.max(acc, y + rowSpan - 1)
    }, 0)

    if (!length)
      return []

    const matrix = Array.from({
      length,
    }, () => Array(columns[dimension.breakpoint]).fill(true))

    widgets.forEach(({ id, layouts, isActive }) => {
      if (!isActive)
        return

      const { x, y, colSpan, rowSpan } = layouts[dimension?.breakpoint ?? 'lg']

      for (let row = y; row < y + rowSpan; row++) {
        if (matrix[row - 1]) {
          matrix[row - 1].fill(id, x - 1, x + colSpan - 1)
        }
      }
    })

    return matrix
  }, [dimension, widgets])

  /**
   * Disable a widget.
   *
   * @param {number} id The widget id.
   *
   * @returns void
   */
  const onDisable = useCallback((id: number) => {
    $disable.mutate({
      id,
      isAdmin,
      clientId,
    })

    setWidgets(widgets => widgets.map(widget => {
      if (widget.id === id) {
        return {
          ...widget,
          isActive: false,
        }
      }

      return widget
    }))
  }, [$disable, clientId, isAdmin])

  return (
    <WidgetContext.Provider
      value={{
        widgets,
        setWidgets,
        dragging,
        setDragging,
        grabbing,
        setGrabbing,
        resizing,
        setResizing,
        dimension,
        setDimension,
        refs,
        setRefs,
        staticMap,
        isAdmin,
        clientId,
        gridRef,
        overlayRef,
        $widgets,
        $update,
        onDisable,
      }}
    >
      <Outlet />
    </WidgetContext.Provider>
  )
}

export default WidgetProvider
