import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { AuthContext } from '@/contexts/Auth'
import IAdmin from '@/interfaces/IAdmin'
import $Auth from '@/services/Auth'

type AdminContextProps = {
  $admin: UseQueryResult<IAdmin, Error>
}

export const AdminContext = createContext<AdminContextProps>({} as AdminContextProps)

const AdminProvider: React.FC = () => {
  const { token, setToken } = useContext(AuthContext)

  const { search } = useLocation()

  const params = useMemo(() => new URLSearchParams(search), [search])

  const $admin = useQuery({
    queryFn: $Auth.admin,
    enabled: !!token,
    queryKey: [
      'admin', token,
    ],
  })

  useEffect(() => {
    if (!params.has('token'))
      return

    setToken(
      params.get('token') as string
    )
  }, [params, search, setToken])

  return (
    <AdminContext.Provider
      value={{
        $admin,
      }}
    >
      {$admin.isSuccess && (
        <Outlet />
      )}
    </AdminContext.Provider>
  )
}

export default AdminProvider
