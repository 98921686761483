import React, { createRef, useEffect } from 'react'
import { hexToRgb } from '@/helpers/Utils'

type Props = React.DetailedHTMLProps<React.CanvasHTMLAttributes<HTMLCanvasElement>, HTMLCanvasElement> & {
  width: number
  height: number
  src: string
  color?: string
}

const Logotipo: React.FC<Props> = ({ width, height, src, color }: Props) => {
  const canvasRef = createRef<HTMLCanvasElement>()

  useEffect(() => {
    const { r, g, b } = hexToRgb(color ?? '#ffffff')

    const image = new Image

    image.src = src
    image.crossOrigin = 'Anonymous'

    image.onload = () => {
      if (!canvasRef.current)
        return

      const context = canvasRef.current.getContext('2d')

      if (!context)
        return

      canvasRef.current.width = width
      canvasRef.current.height = height

      const ratio = Math.min(width / image.width, height / image.height)

      const x = (canvasRef.current.width - image.width * ratio) / 2
      const y = (canvasRef.current.height - image.height * ratio) / 2

      context.drawImage(image, x, y, image.width * ratio, image.height * ratio)

      const imageData = context.getImageData(0, 0, image.width, image.height)

      for (let i = 0; i < imageData.data.length; i += 4) {
        imageData.data[i] = r
        imageData.data[i + 1] = g
        imageData.data[i + 2] = b
      }

      context.putImageData(imageData, 0, 0)
    }

    image.onerror = error => console.error(error)
  }, [canvasRef, color, height, src, width])

  return (
    <canvas ref={canvasRef} />
  )
}

export default Logotipo
