import React, { useCallback, useMemo } from 'react'
import IWidget from '@/interfaces/IWidget'
import Platforms from '@/components/Widgets/Platforms/Platforms'
import AbsoluteAccesses from '@/components/Widgets/AbsoluteAccesses/AbsoluteAccesses'
import RelativeAccesses from '@/components/Widgets/RelativeAccesses/RelativeAccesses'
import ScheduledActivities from '@/components/Widgets/ScheduledActivities/ScheduledActivities'
import ActiveStudents from '@/components/Widgets/ActiveStudents/ActiveStudents'
import AreaAverage from '@/components/Widgets/AreaAverage/AreaAverage'

type WidgetComponents = {
  [key in IWidget['key']]: (widget: IWidget) => JSX.Element
}

const useWidget = () => {
  const widgets: WidgetComponents = useMemo(() => {
    return {
      platforms: (widget: IWidget) => (
        <Platforms widget={widget} />
      ),
      absoluteAccesses: (widget: IWidget) => (
        <AbsoluteAccesses widget={widget} />
      ),
      relativeAccesses: (widget: IWidget) => (
        <RelativeAccesses widget={widget} />
      ),
      scheduledActivities: (widget: IWidget) => (
        <ScheduledActivities widget={widget} />
      ),
      activeStudents: (widget: IWidget) => (
        <ActiveStudents widget={widget} />
      ),
      areaAverage: (widget: IWidget) => (
        <AreaAverage widget={widget} />
      ),
    }
  }, [])

  /**
   * Get widget by name.
   *
   * @returns {JSX.Element}
   */
  const render = useCallback((widget: IWidget) => {
    if (!widgets[widget.key])
      return null

    return widgets[widget.key](widget)
  }, [widgets])

  return {
    render,
  }
}

export default useWidget
