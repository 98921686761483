import React, { memo, useContext } from 'react'
import { useQuery } from '@tanstack/react-query'
import { Box, Progress } from './ActiveStudents.styles'
import IWidget from '@/interfaces/IWidget'
import $Widget from '@/services/Widget'
import { WidgetContext } from '@/contexts/Widget'

const ActiveStudents: React.FC<{ widget: IWidget }> = memo<{ widget: IWidget }>(({ widget }) => {
  const { isAdmin, clientId } = useContext(WidgetContext)

  const $widget = useQuery({
    queryFn: $Widget.find,
    queryKey: [
      widget.key, widget.id, clientId, isAdmin,
    ],
  })

  const data = $widget.data || []

  return (
    <div className="container-fluid">
      {data && data.map(({ id, students, studentsAccessed, studentsNotAccessed }) => {
        const percentageStudentsAccessed = (studentsAccessed / students) * 100
        const percentageStudentsNotAccessed = (studentsNotAccessed / students) * 100

        return (
          <div className="row"key={`active-students-${id}`}>
            <div className="row">
              <b className="d-flex justify-content-center">
                Acesso total de alunos
              </b>
            </div>

            <div className="row">
              <p className="text-center">
                <b>{students}</b> alunos cadastrados
              </p>
            </div>

            <div className="row">
              <div className="col-2">
                {studentsAccessed}<br />{percentageStudentsAccessed.toFixed(0)}%
              </div>

              <div className="col-8">
                <Progress className="progress">
                  <div className="progress-bar" style={{width: `${percentageStudentsAccessed.toFixed(0)}%`}} role="progressbar"
                    aria-valuenow={percentageStudentsAccessed}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  ></div>
                </Progress>
              </div>

              <div className="col-2">
                {studentsNotAccessed}<br />{percentageStudentsNotAccessed.toFixed(0)}%
              </div>
            </div>

            <Box className="row justify-content-center align-items-center mt-2">
              <div className="col-4">
                <div className="row align-items-center">
                  <div className="col-2">
                    <div className="box box-accessed"></div>
                  </div>

                  <div className="col text-footer pe-0">
                    alunos que acessaram
                  </div>
                </div>
              </div>

              <div className="col-4">
                <div className="row align-items-center">
                  <div className="col-2">
                    <div className="box box-not-accessed"></div>
                  </div>

                  <div className="col text-footer pe-0">
                    alunos que não acessaram
                  </div>
                </div>
              </div>
            </Box>
          </div>
        )
      })}
    </div>
  )
}, ({ widget }, { widget: { id } }) => {
  return id === widget.id
})

ActiveStudents.displayName = 'ActiveStudents'

export default ActiveStudents
