import React, { useContext } from 'react'
import { useQuery } from '@tanstack/react-query'
import { Bar } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import IWidget from '@/interfaces/IWidget'
import $Widget from '@/services/Widget'
import { WidgetContext } from '@/contexts/Widget'

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ChartDataLabels,
  Title,
  Tooltip,
  Legend
)

const options = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      grid: {
        display: false,
        drawBorder: false,
      },
      beginAtZero: false,
    },
    y: {
      grid: {
        display: false,
        drawBorder: false,
      },
      beginAtZero: false,
      ticks: {
        display: false
      },
    }
  },
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: true,
      text: 'N° de acessos de alunos em 2023',
      font: {
        size: 13,
        weight: 600,
        font: 'Evo, sans-serif',
        color: '#212529',
      }
    },
    datalabels: {
      display: true,
    },
  },
}

const AbsoluteAccesses: React.FC<{ widget: IWidget }> = ({ widget }) => {
  const { isAdmin, clientId } = useContext(WidgetContext)

  const $widget = useQuery({
    queryFn: $Widget.find,
    queryKey: [
      widget.key, widget.id, clientId, isAdmin,
    ],
  })

  const data = $widget.data || []

  return (
    <Bar
      options={options}
      data={{
        labels: data.map(({ name }) => name),
        datasets: [
          {
            label: 'Qtd. Acessos',
            data: data.map(({ accessAmount }) => accessAmount),
            backgroundColor: '#59196F',
            datalabels: {
              color: 'black',
              align: ({dataIndex, dataset}: any) => dataset.data[dataIndex] > 0 ? 'top' : 'end',
              anchor: ({dataIndex, dataset}: any) => dataset.data[dataIndex] > 0 ? 'end' : 'center',
              font: {
                size: 13,
              },
            },
          }
        ],
      }}
    />
  )
}

export default AbsoluteAccesses
