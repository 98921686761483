import React, { createContext, useState } from 'react'
import { Outlet } from 'react-router-dom'

type AppContextProps = {
  isCollapsed: boolean
  setIsCollapsed: React.Dispatch<React.SetStateAction<boolean>>
}

export const AppContext = createContext<AppContextProps>({} as AppContextProps)

const AppProvider: React.FC = () => {
  const [ isCollapsed, setIsCollapsed ] = useState<boolean>(true)

  return (
    <AppContext.Provider
      value={{
        isCollapsed,
        setIsCollapsed,
      }}
    >
      <Outlet />
    </AppContext.Provider>
  )
}

export default AppProvider
