import styled from 'styled-components'

export const Container = styled.div`
  > button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: transparent;
    color: var(--text-color);
    text-decoration: none;
    border: none;
    padding: 0;
    margin-bottom: 0.25rem;

    > span {
      width: 2rem;
      height: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--primary-color);
      border-radius: 4px;
      color: #fff;
    }

    &:hover {
      color: var(--primary-color);

      > span {
        background-color: var(--secondary-color);
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
`
