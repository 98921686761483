import { useCallback, useContext, useEffect, useMemo } from 'react'
import { WidgetContext } from '@/contexts/Widget'
import { clamp } from 'lodash'
import useOverlay from './useOverlay'

const useDrag = () => {
  const { dragging, dimension, refs, gridRef, staticMap } = useContext(WidgetContext)

  const overlay = useOverlay()

  /**
   * Get the element that is currently being dragged.
   */
  const element = useMemo(() => {
    return dragging ? refs[dragging.widget.id].current : null
  }, [dragging, refs])

  /**
   * Handle the mouse move event.
   *
   * @param {MouseEvent} event - The mouse event.
   *
   * @returns {void}
   */
  const onMouseMove = useCallback(({ clientX, clientY }: MouseEvent) => {
    const grid = gridRef.current?.getBoundingClientRect()

    if (!dragging || !element || !grid || !dimension)
      return

    const { widget: { layouts }, offset } = dragging
    const { column, row } = dimension

    const rect = element.getBoundingClientRect()

    const mouse = {
      x: clientX - offset.x,
      y: clientY - offset.y,
    }

    const delta = {
      x: clamp(mouse.x - grid.left, 0, grid.width - rect.width),
      y: Math.max(0, mouse.y - grid.top),
    }

    const x = Math.max(1, Math.round(delta.x / column) + 1)
    const y = Math.max(1, Math.round(delta.y / row) + 1)

    element.style.left = `${mouse.x}px`
    element.style.top = `${mouse.y}px`

    if (staticMap[y - 1] && staticMap[y - 1][x - 1] && staticMap[y - 1][x - 1] !== true && staticMap[y - 1][x - 1] !== dragging.widget.id)
      return

    element.dataset.x = String(x)
    element.dataset.y = String(y)

    const { colSpan, rowSpan } = layouts[dimension?.breakpoint ?? 'lg']

    overlay.move({
      x,
      y,
      colSpan,
      rowSpan,
    })
  }, [gridRef, dragging, element, dimension, staticMap, overlay])

  useEffect(() => {
    document.addEventListener('mousemove', onMouseMove)

    return () => {
      document.removeEventListener('mousemove', onMouseMove)
    }
  }, [onMouseMove])
}

export default useDrag
