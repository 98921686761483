import React, { memo, useContext, useEffect, useMemo, useRef } from 'react'
import { MdClose } from 'react-icons/md'
import { WidgetContext } from '@/contexts/Widget'
import { Container } from './Widget.styles'
import IWidget from '@/interfaces/IWidget'
import useWidget from '@/hooks/useWidget'
import Draggable from './Draggable/Draggable'
import Resizable from './Resizable/Resizable'
import Breakpoint from '@/types/Breakpoint'

type Props = {
  widget: IWidget
}

const Widget: React.FC<Props> = memo<Props>(({ widget }) => {
  const { dimension, dragging, grabbing, resizing, onDisable, setRefs } = useContext(WidgetContext)

  const widgetRef = useRef<HTMLDivElement>(null)

  const { render } = useWidget()

  const { id, layouts, isStatic } = useMemo(() => widget, [widget])

  const { x, y, colSpan, rowSpan } = layouts[dimension?.breakpoint ?? 'lg']

  useEffect(() => {
    const element = widgetRef.current as HTMLDivElement

    element.dataset.x = String(x)
    element.dataset.y = String(y)
    element.dataset.colSpan = String(colSpan)
    element.dataset.rowSpan = String(rowSpan)
    element.dataset.isStatic = String(isStatic)

    setRefs(refs => ({
      ...refs,
      [id]: widgetRef,
    }))
  }, [id, x, y, colSpan, rowSpan, setRefs, isStatic])

  const onClose = ({ id }: IWidget) => {
    onDisable(id)
  }

  const className = useMemo(() => {
    if (dragging && dragging.widget.id === widget.id)
      return 'widget is-dragging'

    if (grabbing && grabbing.widget.id === widget.id)
      return 'widget is-grabbing'

    if (resizing && resizing.widget.id === widget.id)
      return 'widget is-resizing'

    if (dragging || grabbing || resizing)
      return 'widget is-static'

    return 'widget'
  }, [dragging, grabbing, resizing, widget.id])

  return (
    <Container
      ref={widgetRef}
      className={className}
      data-id={id}
      style={{
        gridColumnStart: x,
        gridRowStart: y,
        gridColumnEnd: `span ${colSpan}`,
        gridRowEnd: `span ${rowSpan}`,
      }}
    >
      <div className="card">
        <div className="card-header">
          <div className={`header-group ${widget.isStatic ? 'ps-2' : ''}`}>
            {widget.isDraggable && (
              <Draggable widget={widget} widgetRef={widgetRef} />
            )}

            <h5 className="card-title">
              {widget.name}
            </h5>
          </div>

          {!widget.isStatic && (
            <button type="button" className="btn btn-close" onClick={() => onClose(widget)}>
              <MdClose size={22} />
            </button>
          )}
        </div>

        <div className={`card-body overflow-y-${widget.enableScroll ? 'auto' : 'none'}`}>
          {render(widget)}
        </div>

        {widget.isResizable && (
          <Resizable widget={widget} widgetRef={widgetRef} />
        )}
      </div>
    </Container>
  )
}, ({ widget }, { widget: { layouts } }) => {
  return Object.entries(layouts).every(([key, value]) => {
    return (
      widget.layouts[key as Breakpoint].x === value.x &&
      widget.layouts[key as Breakpoint].y === value.y &&
      widget.layouts[key as Breakpoint].colSpan === value.colSpan &&
      widget.layouts[key as Breakpoint].rowSpan === value.rowSpan
    )
  })
})

Widget.displayName = 'Widget'

export default Widget
