import axios from 'axios'
import { QueryFunction } from '@tanstack/react-query'
import IUser from '@/interfaces/IUser'
import ISchool from '@/interfaces/ISchool'
import IPlatform from '@/interfaces/IPlatform'
import IAdmin from '@/interfaces/IAdmin'

const user: QueryFunction<IUser, [ string, string | null, number | null ]> = ({ queryKey: [ _, token, schoolId ] }) => {
  return axios.get('auth/user', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      schoolId,
    },
  }).then(({ data }) => data)
}

const admin: QueryFunction<IAdmin, [ string, string | null ]> = ({ queryKey: [ _, token ] }) => {
  return axios.get('auth/admin', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then(({ data }) => data)
}

const platforms: QueryFunction<IPlatform[]> = () => {
  return axios.get('auth/platforms').then(({ data }) => data)
}

const schools: QueryFunction<ISchool[]> = () => {
  return axios.get('auth/schools').then(({ data }) => data)
}

const $Auth = {
  user,
  admin,
  schools,
  platforms,
}

export default $Auth
