import React from 'react'
import { QueryClientProvider } from '@tanstack/react-query'
import { config } from '@/bootstrap/bootstrap'
import ReactDOM from 'react-dom/client'
import reportWebVitals from './reportWebVitals'
import Router from './routes/Router'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

root.render(
  <QueryClientProvider client={config.queryClient}>
    <Router />
  </QueryClientProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
