import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import AuthProvider from '@/contexts/Auth'
import WidgetProvider from '@/contexts/Widget'
import App from '@/layouts/App/App'
import Login from '@/screens/Auth/Login'
import Grid from '@/screens/Grid/Grid'
import AdminProvider from '@/contexts/Admin'
import Admin from '@/layouts/Admin/Admin'
import AppProvider from '@/contexts/App'
import UserProvider from '@/contexts/User'

const Router: React.FC<any> = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<AppProvider />}>
          <Route element={<AuthProvider />}>
            <Route element={<UserProvider />}>
              <Route element={<WidgetProvider />}>
                <Route element={<App />}>
                  <Route index element={<Grid />} />
                </Route>
              </Route>
            </Route>

            <Route element={<AdminProvider />}>
              <Route element={<WidgetProvider isAdmin />}>
                <Route element={<Admin />}>
                  <Route path="/admin" element={<Grid />} />
                </Route>
              </Route>
            </Route>

            <Route path="login" element={<Login />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default Router
