export const hexToRgb = (hex: string) : { r: number, g: number, b: number, toString: () => string } => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)

  if (!result)
    return {
      r: 0,
      g: 0,
      b: 0,
      toString: () => '0, 0, 0',
    }

  const r = parseInt(result[1], 16)
  const g = parseInt(result[2], 16)
  const b = parseInt(result[3], 16)

  return {
    r,
    g,
    b,
    toString: () => `${r}, ${g}, ${b}`
  }
}
