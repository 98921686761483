import React, { useContext } from 'react'
import { useQuery } from '@tanstack/react-query'
import { FaArrowRight } from 'react-icons/fa'
import { Container } from './Platforms.styles'
import IWidget from '@/interfaces/IWidget'
import $Widget from '@/services/Widget'
import { WidgetContext } from '@/contexts/Widget'

const Platforms: React.FC<{ widget: IWidget }> = ({ widget }) => {
  const { isAdmin, clientId } = useContext(WidgetContext)

  const $widget = useQuery({
    queryFn: $Widget.find,
    queryKey: [
      widget.key, widget.id, clientId, isAdmin,
    ],
  })

  return (
    <Container>
      {$widget.data?.map(({ id, name }) => (
        <button className="btn" key={`platform-access-${id}`}>
          {name} <span><FaArrowRight /></span>
        </button>
      ))}
    </Container>
  )
}

export default Platforms
