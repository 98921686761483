import styled from 'styled-components'

export const Container = styled.div`
  padding: 0.3rem;
  overflow: hidden;
  transition: opacity 0.2s ease;
  opacity: 1;
  z-index: 2;

  > .card {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    position: relative;
    border-radius: 0;
    overflow: hidden;

    > .card-header {
      height: 3rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 0.35rem;
      padding-left: 0.35rem;
      position: relative;
      border-radius: 0;

      > .header-group {
        display: flex;
        align-items: center;
        gap: 0.25rem;

        > .draggable {
          display: flex;
          align-items: center;
          justify-content: center;
          transition: color 0.2s ease;
          cursor: grab;
          color: #ccc;
          right: 0;
          top: 0;

          > svg {
            pointer-events: none;
          }

          &:hover,
          &:active {
            color: var(--primary-color);
          }
        }

        > .card-title {
          font-weight: 400;
          color: var(--primary-color);
          font-size: 1rem;
          position: relative;
          margin: 0;
          top: 2px;
        }
      }

      > .btn-close {
        width: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: none;
        color: #999;
        padding: 0;

        &:hover {
          color: var(--primary-color);
        }
      }
    }

    > .card-body {
      padding: 16px;
      flex-grow: 1;

      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-track {
        background-color: #f0f0f0;
        border: 1px solid #fff;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #ccc;
        border: 1px solid #fff;
        border-radius: 5px;
      }

      &:hover::-webkit-scrollbar-thumb {
        background-color: var(--primary-color);
        border-radius: 5px;
      }
    }

    > .resizable {
      width: 56px;
      height: 56px;
      cursor: nwse-resize;
      background-color: #fff;
      transform: rotate(-45deg);
      padding: 0.2rem 0 0;
      position: absolute;
      bottom: -34px;
      right: -34px;
      z-index: 5;
      border: 0;

      > div {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        pointer-events: none;
        gap: 2px;

        &::before,
        &::after {
          height: 2px;
          content: '';
          background-color: #999;
        }

        &::before {
          width: 12px;
        }

        &::after {
          width: 4px;
        }
      }

      &:hover > div::before,
      &:hover > div::after {
        background-color: var(--primary-color);
      }
    }
  }

  &.is-dragging,
  &.is-grabbing,
  &.is-resizing {
    position: fixed;
    user-select: none;
    opacity: 0.7;
  }

  &.is-grabbing {
    pointer-events: none;
    opacity: 0;
  }

  &.is-dragging > .card > .card-header > .header-group > .draggable {
    cursor: grabbing;
  }

  &.is-static {
    pointer-events: none;
    user-select: none;
  }

  &:hover,
  &.active {
    z-index: 3;
  }
`
