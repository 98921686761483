import React, { useContext, useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import { Container } from './AreaAverage.styles'
import IWidget from '@/interfaces/IWidget'
import $Widget from '@/services/Widget'
import { WidgetContext } from '@/contexts/Widget'

type Area = {
  id: number
  name: string
  evaluations: {
    id: number
    name: string
    lc: number
    ch: number
    cn: number
    mt: number
    rd: number
    geral: number
    month: string
    date: string
  }[]
}

const AreaAverage: React.FC<{ widget: IWidget }> = ({ widget }) => {
  const { isAdmin, clientId } = useContext(WidgetContext)

  const $widget = useQuery({
    queryFn: $Widget.find,
    queryKey: [
      widget.key, widget.id, clientId, isAdmin,
    ],
  })

  const areas = useMemo(() => {
    if (!$widget.data)
      return []

    return $widget.data.reduce((acc, { assessmentId, assessmentName, ...evaluation }) => {
      if (!acc[assessmentId]) {
        acc[assessmentId] = {
          id: assessmentId,
          name: assessmentName,
          evaluations: [],
        } as Area
      }

      acc[assessmentId].evaluations.push(evaluation)

      return acc
    }, []) as Area[]
  }, [$widget.data])

  return (
    <Container>
      {areas.map(area => (
        <div className="area" key={`area-average-${area.id}`}>
          <h6 className="name mb-1">{area.name}</h6>

          <table className="table table-bordered table-sm mb-0">
            <thead>
              <tr>
                <th>Nota</th>
                <th>Nota + RD</th>
                <th>LC</th>
                <th>CH</th>
                <th>CN</th>
                <th>MT</th>
                <th>RD</th>
              </tr>
            </thead>

            <tbody>
              {area.evaluations.map(evaluation => (
                <tr key={`evaluation-${evaluation.id}`}>
                  <td>{evaluation.geral}</td>
                  <td>{evaluation.geral + evaluation.rd}</td>
                  <td>{evaluation.lc}</td>
                  <td>{evaluation.ch}</td>
                  <td>{evaluation.cn}</td>
                  <td>{evaluation.mt}</td>
                  <td>{evaluation.rd}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ))}
    </Container>
  )
}

export default AreaAverage