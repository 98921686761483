import queryClient from './configuration/queryClient'

import './configuration/document'
import './configuration/axios'
import './configuration/highcharts'
import './configuration/moment'

import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import 'flexmonster/flexmonster.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-tooltip/dist/react-tooltip.css'

import '@/assets/scss/app.scss'

export const config = {
  queryClient,
}
