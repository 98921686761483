import React, { useContext, useState } from 'react'
import { Dropdown, Spinner } from 'react-bootstrap'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Link } from 'react-router-dom'
import { Container } from './Header.styles'
import { ReactComponent as User } from '@/assets/svg/user.svg'
import { UserContext } from '@/contexts/User'
import $Auth from '@/services/Auth'
import $SingleSignOn from '@/services/SingleSignOn'
import Logotipo from '@/components/Logotipo/Logotipo'
import Schools from './Schools/Schools'
import IPlatform from '@/interfaces/IPlatform'

const Header: React.FC<any> = () => {
  const { $user, schoolId } = useContext(UserContext)

  const [ isRedirectLoading, setIsRedirectLoading ] = useState<boolean>(false)

  const $platforms = useQuery({
    queryFn: $Auth.platforms,
    enabled: !!schoolId,
    queryKey: [
      'platforms', schoolId,
    ],
  })

  const $redirect = useMutation({
    mutationFn: $SingleSignOn.platform,
    onMutate: () => {
      setIsRedirectLoading(true)
    },
    onSuccess: ({ redirectUrl }) => {
      window.location.href = redirectUrl
    },
  })

  const isLoading = isRedirectLoading || $platforms.isLoading || $user.isLoading

  return (
    <Container>
      <div className="container-fluid">
        <figure className="logotipo">
          <Link to="/">
            <Logotipo width={200} height={60} src={require('@/assets/svg/evolucional.svg').default} />
          </Link>
        </figure>

        <div className="topbar">
          <div className="schools">
            <Schools />
          </div>

          <div className="menu">
            <div className="user">
              <Dropdown>
                <Dropdown.Toggle variant="light">
                  <User />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item>Sair</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>

            <div className="platform" style={{ cursor: isLoading ? 'wait' : 'default' }}>
              <Dropdown>
                <Dropdown.Toggle variant="danger">
                  Dashboard {isLoading && <Spinner animation="border" variant="light" size="sm" className="ms-2" />}
                </Dropdown.Toggle>

                {$platforms.data && (
                  <Dropdown.Menu>
                    {$platforms.data.map(({ id, name }: IPlatform) => (
                      <Dropdown.Item onClick={() => $redirect.mutate(id)} key={id}>
                        {name}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                )}
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default Header
