import React, { useContext } from 'react'
import { Outlet } from 'react-router-dom'
import { AppContext } from '@/contexts/App'
import { Container } from './Admin.styles'
import Sidebar from './Sidebar/Sidebar'

const Admin: React.FC<any> = () => {
  const { isCollapsed } = useContext(AppContext)

  return (
    <Container>
      <main className={`main ${isCollapsed ? 'is-collapsed' : ''}`}>
        <section className="content">
          <Outlet />
        </section>

        <Sidebar />
      </main>
    </Container>
  )
}

export default Admin
