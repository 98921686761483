import styled from 'styled-components'

export const Container = styled.div`
  > .area {
    margin-bottom: 1rem;

    > .table {
      border-radius: 0.25rem;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
`
