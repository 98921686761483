import styled from 'styled-components'

export const Aside = styled.aside`
  height: 100%;
  position: relative;
  grid-area: sidebar;
  user-select: none;

  > .btn {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px 0 0 6px;
    transform: translateX(-100%);
    position: absolute;
    top: 200px;
    padding: 0;
    z-index: 3;
    left: 0;
  }

  > .widgets {
    width: 100%;
    height: 100%;
    transition: padding 0.2s ease, opacity 0.2s ease;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-left: 1px solid #ddd;
    background-color: #fff;
    overflow: hidden;
    padding: 1rem;

    > .accordion {
      > .accordion-item {
        > .accordion-collapse {
          > .accordion-body {
            > .widget {
              background-color: transparent;
              position: relative;
              padding: 0;
              border: 0;

              > figure {
                pointer-events: none;

                > img {
                  width: 100%;
                  border: 1px solid var(--primary-color);
                  border-radius: 4px;
                }
              }

              > .grabbable {
                width: 100%;
                height: 100%;
                pointer-events: all;
                position: absolute;
                cursor: grab;
                z-index: 1;
                left: 0;
                top: 0;
              }

              &.is-grabbing {
                cursor: grabbing;

                > .grabbable {
                  cursor: grabbing;
                  position: fixed;
                  z-index: 1000;
                }
              }
            }
          }
        }
      }
    }
  }
`
