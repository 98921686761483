import styled from 'styled-components'

export const Container = styled.div`
  position: relative;

  > .grid {
    width: 100%;

    > .widgets {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-auto-rows: 110px;

      > .overlay {
        pointer-events: none;
        transition: all 0.2s ease-in-out;
        grid-column-start: 1;
        grid-row-start: 1;
        grid-column-end: 2;
        grid-row-end: 2;
        padding: 0.3rem;
        opacity: 0;

        > div {
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.05);
        }
      }
    }
  }
`
