import styled from 'styled-components'

export const Container = styled.header`
  width: 100%;
  display: flex;
  position: sticky;
  background-color: var(--primary-color);
  z-index: 999;
  top: 0;

  > .container-fluid {
    height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 3rem;

    > .logotipo {
      display: flex;
      align-items: center;
      position: relative;
      margin: 0;

      > a {
        display: flex;
        align-items: center;
      }
    }

    > .topbar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 3rem;

      > .schools {
        width: 260px;
      }

      > .menu {
        display: flex;
        align-items: center;
        margin-left: 16px;

        > .notifications,
        > .user {
          margin-right: 16px;

          @media (max-width: 576px) {
            margin-right: 5px;
          }

          > .dropdown {
            > .dropdown-menu {
              > .dropdown-item {
                > small {
                  display: block;
                  text-transform: uppercase;
                  color: #555;
                  font-size: 0.7rem;
                }

                &.text {
                  pointer-events: none;
                }
              }
            }

            > .btn {
              width: 48px;
              height: 48px;
              border-radius: 100%;
              outline: none;
              border: none;
              padding: 0;

              > svg {
                max-width: 32px;
                max-height: 32px;
              }

              &::after {
                display: none;
              }
            }
          }
        }

        > .platform {
          > .dropdown {
            > .btn {
              display: flex;
              align-items: center;
              border-radius: 60px;
              padding: 8px 20px;
              background-color: var(--secondary-color);
              border-color: var(--secondary-color);
              filter: brightness(90%);
              box-shadow: none;
              border: 0;

              @media (max-width: 576px) {
                > span {
                  display: none;
                }
              }

              &:hover {
                filter: brightness(100%);
              }

              &::after {
                margin-left: 12px;
              }
            }

            > #sidebarWeb {
              display: block;
            }

            > #sidebarMobile {
              display: none;
            }

            @media (max-width: 576px) {
              > #sidebarWeb {
                display: none !important;
              }

              > #sidebarMobile {
                display: block;

                > svg {
                  max-width: 32px;
                  max-height: 32px;
                }
              }
            }
          }
        }
      }
    }
  }
`
