import React, { useContext, useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import moment from 'moment'
import { Container } from './ScheduledActivities.styles'
import IWidget from '@/interfaces/IWidget'
import $Widget from '@/services/Widget'
import { WidgetContext } from '@/contexts/Widget'

const ScheduledActivities: React.FC<{ widget: IWidget }> = ({ widget }) => {
  const { isAdmin, clientId } = useContext(WidgetContext)

  const $widget = useQuery({
    queryFn: $Widget.find,
    queryKey: [
      widget.key, widget.id, clientId, isAdmin,
    ],
  })

  const data = useMemo(() => {
    return $widget.data?.sort((a, b) => {
      return Number(a.month) - Number(b.month)
    }) || []
  }, [$widget.data])

  return (
    <Container>
      {data.map(activity => {
        const date = moment(activity.date)

        return (
          <div className="activity" key={`scheduled-activities-${activity.id}`}>
            <div className="date">
              {date.format('yyyy')} <small className="month">{date.format('MMM').toUpperCase()}</small>
            </div>

            <div className="details">
              <div className="name">{activity.name}</div>
            </div>
          </div>
        )
      })}
    </Container>
  )
}

export default ScheduledActivities
