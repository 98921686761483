import axios from 'axios'
import { MutationFunction } from '@tanstack/react-query'

export const platform: MutationFunction<{ redirectUrl: string }, number> = (id) => {
  return axios.post(`single-sign-on/platforms/${id}`).then(({ data }) => data)
}

const $SingleSignOn = {
  platform,
}

export default $SingleSignOn
