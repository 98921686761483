import styled from 'styled-components'

export const Progress = styled.div`
  height: 40px;
  border-radius: 0px;
  background-color: #767676;
  > .progress-bar {
    background-color: #59196F;
  }
`

export const Box = styled.div`
  .box {
    height: 15px;
    width: 15px;
    border-radius: 5px;
    font-weight: 300;
  }

  .box-accessed {
    background-color: #59196F;
  }

  .box-not-accessed {
    background-color: #767676;
  }

  .text-footer {
    font-size: 13px;
  }
`
