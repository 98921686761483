import styled from 'styled-components'

export const Container = styled.div`
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 100px 1fr;

  > main {
    display: grid;
    grid-template-columns: 1fr 300px;
    transition: grid-template-columns 0.2s ease-in-out;
    grid-template-rows: 1fr;
    grid-template-areas:
      'content sidebar';

    > section {
      padding: 3rem 0;
      grid-area: content;
    }

    &.is-collapsed {
      grid-template-columns: 1fr 1px;

      > .sidebar {
        > .widgets {
          padding-left: 0;
          padding-right: 0;
          opacity: 0;
        }
      }
    }
  }
`
