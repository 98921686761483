import { useCallback, useContext, useEffect, useMemo } from 'react'
import { columns, WidgetContext } from '@/contexts/Widget'
import Breakpoint from '@/types/Breakpoint'

const useDimension = () => {
  const { dimension, setDimension, gridRef } = useContext(WidgetContext)

  const gridTemplateColumns = useMemo(() => {
    return `repeat(${columns[dimension?.breakpoint ?? 'lg']}, 1fr)`
  }, [dimension?.breakpoint])

  /**
   * Handle the resize event.
   *
   * @returns {void}
   */
  const onResize = useCallback(() => {
    const grid = gridRef.current as HTMLDivElement

    const { width } = grid.getBoundingClientRect()

    let breakpoint: Breakpoint = 'lg'

    if (width <= 576) {
      breakpoint = 'xs'
    } else if (width <= 768) {
      breakpoint = 'sm'
    } else if (width <= 992) {
      breakpoint = 'md'
    }

    setDimension(() => ({
      breakpoint,
      column: Math.floor(width / columns[breakpoint]),
      row: 110,
    }))
  }, [gridRef, setDimension])

  useEffect(() => {
    onResize()

    window.addEventListener('resize', onResize)

    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [onResize])

  return {
    gridTemplateColumns,
  }
}

export default useDimension
