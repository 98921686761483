import React, { MouseEvent, useCallback, useContext, useRef } from 'react'
import { MdDragIndicator } from 'react-icons/md'
import { WidgetContext } from '@/contexts/Widget'
import IWidget from '@/interfaces/IWidget'
import useOverlay from '@/hooks/useOverlay'

type Props = {
  widget: IWidget
  widgetRef: React.RefObject<HTMLDivElement>
}

const Draggable: React.FC<Props> = ({ widget, widgetRef }) => {
  const { dimension, dragging, setDragging, setWidgets, refs, $update } = useContext(WidgetContext)

  const draggableRef = useRef<HTMLDivElement>(null)

  const overlay = useOverlay()

  const onMouseDown = useCallback(({ clientX, clientY }: MouseEvent<HTMLDivElement>) => {
    const element = widgetRef.current as HTMLDivElement

    const rect = element.getBoundingClientRect()

    element.style.width = `${rect.width}px`
    element.style.height = `${rect.height}px`
    element.style.left = `${rect.left}px`
    element.style.top = `${rect.top}px`

    setDragging({
      widget,
      offset: {
        x: clientX - rect.left,
        y: clientY - rect.top,
      },
    })

    const { x, y, colSpan, rowSpan } = widget.layouts[dimension?.breakpoint ?? 'lg']

    overlay.move({
      x,
      y,
      colSpan,
      rowSpan,
    })
  }, [dimension?.breakpoint, overlay, setDragging, widget, widgetRef])

  const onMouseUp = useCallback(() => {
    if (!dragging)
      return

    const { id } = dragging.widget

    setDragging(null)

    const element = widgetRef.current as HTMLDivElement

    element.style.width = 'auto'
    element.style.height = 'auto'
    element.style.left = 'initial'
    element.style.top = 'initial'

    setWidgets(widgets => [
      ...widgets.map(widget => {
        const element = refs[widget.id]?.current

        if (!element)
          return widget

        const { x, y, colSpan, rowSpan } = element.dataset

        widget.layouts[dimension?.breakpoint ?? 'lg'] = {
          x: Number(x),
          y: Number(y),
          colSpan: Number(colSpan),
          rowSpan: Number(rowSpan),
        }

        if (widget.id === id) {
          $update.mutate(widget)
        }

        return widget
      })
    ])

    overlay.hide()
  }, [dragging, setDragging, widgetRef, setWidgets, overlay, refs, dimension?.breakpoint, $update])

  return (
    <div ref={draggableRef} className="draggable" onMouseDown={onMouseDown} onMouseUp={onMouseUp}>
      <MdDragIndicator size={22} />
    </div>
  )
}

export default Draggable
