import React, { useContext, useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { ChartOptions } from 'chart.js'
import { Pie } from 'react-chartjs-2'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import IWidget from '@/interfaces/IWidget'
import $Widget from '@/services/Widget'
import { WidgetContext } from '@/contexts/Widget'

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels)

const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    title: {
      display: true,
      text: '% de acessos de alunos em 2023',
      font: {
        size: 13,
        weight: 600,
        font: 'Evo, sans-serif',
        color: '#212529',
      }
    },
    legend: {
      display: true,
      position: 'bottom'
    }
  },
} as ChartOptions<'pie'>

const RelativeAccesses: React.FC<{ widget: IWidget }> = ({ widget }) => {
  const { isAdmin, clientId } = useContext(WidgetContext)

  const $widget = useQuery({
    queryFn: $Widget.find,
    queryKey: [
      widget.key, widget.id, clientId, isAdmin,
    ],
  })

  const data = useMemo(() => {
    if (!$widget.data)
      return null

    const data = $widget.data

    return {
      labels: data.map(({ name }) => name),
      datasets: [
        {
          data: data.map(({ accessPorcentage }) => accessPorcentage),
          backgroundColor: [
            '#060181',
            '#59196F',
            '#832765',
            '#A8325D',
            '#F74A4B',
            '#EE7576',
            '#E2AEAE',
          ],
          borderWidth: 0,
          datalabels: {
            color: 'white',
            formatter: function(value: number) {
              return value + '%'
            },
            font: {
              size: 13,
            },
            align: ({ dataIndex, dataset }: any) => {
              return dataset.data[dataIndex] > 0 ? 'start' : 'end'
            },
            anchor: ({ dataIndex, dataset }: any) => {
              return dataset.data[dataIndex] > 0 ? 'end' : 'center'
            },
          },
        },
      ],
    }
  }, [$widget.data])

  if (!data)
    return null

  return (
    <Pie
      options={options}
      data={data}
    />
  )
}

export default RelativeAccesses
