import { useCallback, useContext, useState } from 'react'
import { WidgetContext } from '@/contexts/Widget'
import Position from '@/types/Position'

const useOverlay = () => {
  const { overlayRef } = useContext(WidgetContext)

  const [ position, setPosition ] = useState<Position>({
    x: 0,
    y: 0,
    colSpan: 0,
    rowSpan: 0,
  })

  /**
   * Move the overlay to the given position.
   *
   * @param {Position} widget - The widget to move the overlay to.
   * @param {(position: Position) => void | undefined} onMovedTo - The callback to call when the overlay is moved to the given position.
   *
   * @returns {void}
   */
  const move = useCallback(({ x, y, colSpan, rowSpan }: Position, onMovedTo?: (position: Position) => void) => {
    if (x === position.x && y === position.y && colSpan === position.colSpan && rowSpan === position.rowSpan)
      return

    const overlay = overlayRef.current as HTMLDivElement

    overlay.style.gridColumnStart = String(x)
    overlay.style.gridRowStart = String(y)
    overlay.style.gridColumnEnd = `span ${colSpan}`
    overlay.style.gridRowEnd = `span ${rowSpan}`
    overlay.style.opacity = '1'

    setPosition(() => {
      position.x = x
      position.y = y
      position.colSpan = colSpan
      position.rowSpan = rowSpan

      onMovedTo?.(position)

      return position
    })
  }, [position, overlayRef])

  /**
   * Hide the overlay.
   *
   * @returns {void}
   */
  const hide = useCallback(() => {
    const overlay = overlayRef.current

    if (!overlay)
      return

    overlay.style.opacity = '0'
  }, [overlayRef])

  return {
    move,
    hide,
  }
}

export default useOverlay
