import styled from 'styled-components'

export const Container = styled.div`
  > .activity {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    border: 1px solid #D9D9D9;
    border-radius: 4px;
    padding: 0.5rem 1rem;
    gap: 1rem;

    > .date {
      width: 36px;
      flex-grow: 0;
      font-weight: 600;
      color: var(--primary-color);

      > .month {
        color: var(--text-color);
      }
    }

    > .details {
      width: 100%;
      flex-grow: 1;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
`
